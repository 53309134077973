import "./comparison.css"

import React, { useState } from "react"
import ReactSelect, { MultiValue } from "react-select"
import { graphql, navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { RatingDetailed, Site, SiteScore } from "@/components/site-card"
import { useQueryParam, StringParam } from "use-query-params"
import { Checkbox, CheckboxGroup } from "rsuite"


import Layout from "@/templates/layout"
import { useTranslation } from "gatsby-plugin-react-i18next"
// @ts-ignore
import countries from "../../../wp/coins/countries"

import IconCheckmark from "@/images/icons/icon-checkmark.inline.svg"
import IconDashboard from "@/images/icons/icon-dashboard.inline.svg"
import IconDatabase from "@/images/icons/icon-database.inline.svg"
import IconFire from "@/images/icons/icon-fire.inline.svg"
import IconLocation from "@/images/icons/icon-location.inline.svg"
import IconMegaphone from "@/images/icons/icon-megaphone.inline.svg"


function unique<T>(data: T[]) {
  return Array.from(new Set(data))
}

function process<T>(data: T[]) {
  return unique(data).sort().map(val => ({
    label: val,
    value: val,
  }))
}

function useBrowser() {
  const [hasMounted, setHasMounted] = React.useState(false)
  React.useEffect(() => { setHasMounted(true) }, [])
  return hasMounted
}

interface State {
  coingeckoCountries: Option[]
  coingeckoCoinIds: Option[]
  languages: Option[]
  currencies: Option[]
  paymentMethods: string[]
  comparisonFeatures: string[]
  advancedComparisonFeatures: string[]
}

function defaultState(): State {
  return {
    coingeckoCountries: [],
    coingeckoCoinIds: [],
    languages: [],
    currencies: [],
    paymentMethods: [],
    comparisonFeatures: [],
    advancedComparisonFeatures: [],
  }
}

const Index = (props: any) => {
  const { i18n } = useTranslation()
  const browser = useBrowser()
  if (i18n.language === "fr_FR") {
    if (browser) {
      navigate('/exchanges')
    } else {
      return <></>
    }
  }

  let allSites = (props.data.sites.nodes as Site[])
    .sort((a, b) => a.menuOrder - b.menuOrder)

  let sites = allSites
  const [compare, setCompare] = useQueryParam("compare", StringParam)
  const [mobileSidebarVisible, setMobileSidebarVisible] = useState(false)

  if (compare) {
    const comparedSites = compare.split("-")
    sites = sites.filter(s => comparedSites.includes(s.slug.replace("-review", "")))
  }
  
  const [state, setState] = useState<State>(defaultState())
  const [currentSort, setCurrentSort] = useState<string>("")

  function updateState(field: string) {
    return (data: (MultiValue<Option>)) => setState({
      ...state,
      [field]: data,
    })
  }

  if (!useBrowser()) {
    return <></>
  }

  const coingeckoCountries = Object.entries(countries).map(([label, value]) => ({ label, value })) as Option[]
  const coingeckoCoinIds = process(sites.map(s => s.siteFields.coingeckoCoinIds).flat())
  const languages = process(sites.map(s => s.siteFields.languages).flat())
  const currencies = process(sites.map(s => s.siteFields.currencies).flat())
  const paymentMethods = [
    { label: 'Bank Transfer', value: 'wire' },
    { label: 'Credit Card', value: 'credit' },
    { label: 'PayPal', value: 'paypal' },
    { label: 'Apple Pay', value: 'applepay' },
    { label: 'Sofort', value: 'sofort' },
    { label: 'Sepa', value: 'sepa' },
    { label: 'AliPay', value: 'alipay' },
    { label: 'BanContact', value: 'bancontact' },
    { label: 'Chaps', value: 'chaps' },
    { label: 'Debit Card', value: 'debit' },
    { label: 'EPS', value: 'eps' },
    { label: 'FPS', value: 'fps' },
    { label: 'GiroPay', value: 'giropay' },
    { label: 'Google Pay', value: 'googlepay' },
    { label: 'Ideal', value: 'ideal' },
    { label: 'Klarna', value: 'klarna' },
    { label: 'MyBank', value: 'mybank' },
    { label: 'Swift', value: 'swift' },

  ]
  const comparisonFeatures = [
    { label: "Has Instant Buy", value: "instant_buy" },
    { label: "Mobile App", value: "mobile_app" },
    { label: "Fiat-Crypto Trading", value: "fiat_trading" },
    { label: "Chat Support", value: "chat_support" },
    { label: "NFT Platform", value: "nft_platform" },
    { label: "Email support", value: "email_support" },
    { label: "Knowledge base", value: "knowledge_base" },
    { label: "Has Academy / Learning platform", value: "academy" },
  ]
  const advancedComparisonFeatures = [
    { label: "Future Exchange", value: "future_exchange" },
    { label: "Option Trading", value: "option_trading" },
    { label: "Short Selling", value: "short_selling" },
    { label: "Leverage", value: "leverage" },
    { label: "Margin Account", value: "margin_account" },
    { label: "Lending", value: "lending" },
    { label: "Borrowing", value: "borrowing" },
  ]

  const seo = {
    title: "Crypto Exchanges Comparison & Filters |MadCrypto.com",
    description: "Compare, filter, and sort the world's largest crypto exchanges to find the best crypto exchanges for you. More than 100 filters are available. (142 char)",
  }

  for (const [key, values] of Object.entries(state)) {
    if (values.length === 0) { continue }
    const valuesSet = new Set((values as Option[]).map(value => 
      typeof value === 'string' ? value : value.value
    ))
    sites = sites.filter(s => {
      const siteValues = (s.siteFields as any)[key as any] as any[]
      const intersection = siteValues.filter(i => valuesSet.has(i) || valuesSet.has(i.text))
      return intersection.length === values.length
    })
  }

  const sorts = [
    { label: "Beginner Score", value: "Beginner Score" },
    { label: "Pro Score", value: "Pro Score" },
    { label: "Assets Score", value: "assets" },
    { label: "Ease of Use Score", value: "easeOfUse" },
    { label: "Fees Score", value: "fees" },
    { label: "Safety Score", value: "safety" },
    { label: "Staking Score", value: "staking" },
    { label: "Trading Features Score", value: "tradingFeatures" },
  ]
  
  if (currentSort) {
    sites = sites.sort((a, b) => {
      if (currentSort === "Beginner Score") { return b.siteFields.beginnerScore - a.siteFields.beginnerScore }
      if (currentSort === "Pro Score") { return b.siteFields.advancedScore - a.siteFields.advancedScore }
      const ra = a.siteFields.ratings as any
      const rb = b.siteFields.ratings as any
      return rb[currentSort] - ra[currentSort]
    })
  }

  return <Layout {...props} seo={seo} title="Crypto Exchanges Comparison" className={`page-comparison ${mobileSidebarVisible ? 'sidebar-visible' : 'sidebar-hidden'}`}>
    <div className="container container-columns">
      <div className="button-sidebar" onClick={() => setMobileSidebarVisible(!mobileSidebarVisible)}></div>
      <div className="mobile-overlay" onClick={() => setMobileSidebarVisible(!mobileSidebarVisible)}></div>
      <div className={`column-sidebar column-sidebar-comparison`}>
        <header>Filters</header>
        <div className="site-filters">

          <div>
            <Filter selected={state.coingeckoCountries} items={coingeckoCountries}
              onChange={updateState("coingeckoCountries")}
              header={<><IconLocation /><span>Country</span></>} />
            <Filter selected={state.languages} items={languages}
              onChange={updateState("languages")}
              header={<><IconLocation /><span>Language</span></>} />
          </div>

          <div className="widget-sorting">
            <div className="widget-sorting-items">
              <header><IconFire /> Sort By</header>
              {sorts.map((sort) => <div className={`sort ${sort.value === currentSort ? 'selected' : 'unselected'}`}
              onClick={() => setCurrentSort(sort.value === currentSort ? "" : sort.value)}>
                {sort.label}
              </div>)}
            </div>
          </div>

          <div>
            <Filter selected={state.currencies} items={currencies}
              onChange={updateState("currencies")}
              header={<><IconDatabase /><span>Fiat</span></>} />
            <Filter selected={state.coingeckoCoinIds} items={coingeckoCoinIds}
              onChange={updateState("coingeckoCoinIds")}
              header={<><IconDatabase /><span>Crypto</span></>} />
          </div>

          <FilterCheckboxes
            header={<><IconCheckmark /> <span>Funding Methods</span></>}
            selected={state.paymentMethods}
            items={paymentMethods}
            show={6}
            onChange={(value: string[]) => { setState({ ...state, paymentMethods: value, }) }} />

          <FilterCheckboxes
            header={<><IconCheckmark /> <span>Features</span></>}
            selected={state.comparisonFeatures}
            items={comparisonFeatures}
            onChange={(value: string[]) => { setState({ ...state, comparisonFeatures: value, }) }} />

          <FilterCheckboxes
            header={<><IconMegaphone /> <span>Advanced Features</span></>}
            selected={state.advancedComparisonFeatures}
            items={advancedComparisonFeatures}
            onChange={(value: string[]) => { setState({ ...state, advancedComparisonFeatures: value, }) }} />

          <a className="button" onClick={() => {
            setState(defaultState())
            setCompare(null)
          }}>Clear Filters</a>
        </div>
      </div>
      <div className="column-primary container-menu-items">
        <h1>Crypto Exchange Comparison</h1>
        <p>Compare and filter the world's best crypto exchanges by assets, features, languages, funding methods, and more.</p>
        <SiteListWidget sites={sites} />
      </div>
    </div>
  
  </Layout>
}

interface Option { value: string, label: string }

interface FilterProps {
  onChange: (data: MultiValue<Option>, actionMeta: any) => void
  selected: Option | Option[]
  header?: JSX.Element
  items: Option[]
}

function Filter(props: FilterProps) {
  return <div className="filter filter-dropdown">
    {props.header && <header>{props.header}</header>}
    <ReactSelect
      value={props.selected}
      isMulti
      options={props.items}
      onChange={props.onChange}
    />
  </div>
}

interface FilterCheckboxesProps {
  onChange: (data: string[]) => void
  selected: string[]
  header?: JSX.Element
  items: Option[]
  show?: number
}

function FilterCheckboxes(props: FilterCheckboxesProps) {
  const [more, setMore] = useState(false)
  const items = more ? props.items : props.items.slice(0, props.show || 4)

  return <div className="filter">
    {props.header && <header>{props.header}</header>}
    <CheckboxGroup
      inline
      name="features"
      value={props.selected}
      onChange={props.onChange}>
      {items.map((item, i) =>
        <Checkbox key={i} value={item.value}>{item.label}</Checkbox>
      )}
    </CheckboxGroup>
    {!more && <div className="show-more" onClick={() => setMore(true)}>Show More</div>}
  </div>

}

const SiteListWidget = (props: { sites: Site[] }) => {
  const classNames = ['widget', 'site-list', 'site-list-comparison']

  return <div className={classNames.join(" ")}>
    {props.sites.map((site, i) => <SiteCardComparison key={i} site={site} />)}
  </div>
}

function SiteCardComparison (props: { site: Site }) {
  const { t } = useTranslation()
  const { site } = props
  const image = {
    data: site.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: site.featuredImage?.node?.alt || ``,
  }

  return <a className="site-card-small" href={`/exchanges/${site.slug}`} rel="noopener nofollow noreferrer" itemProp="url">
    <div className="site-card-info">
      {image?.data && <GatsbyImage className="image" image={image.data} alt={image.alt} objectFit="contain" />}
      {site.siteFields.beginnerScore && <RatingDetailed className="hidden-mobile" score={site.siteFields.beginnerScore} label={t("Beginner Score")} />}
      {site.siteFields.advancedScore && <RatingDetailed className="hidden-mobile" score={site.siteFields.advancedScore} label={t("Pro Score")} />}
    </div>
    <SiteScores className="scores" site={site} />
  </a>
}

function SiteScores(props: { className?: string, site: Site }) {
  const { className, site } = props
  const ratings = site.siteFields.ratings || {}

  const classNames = ["site-scores"]
  if (className) { classNames.push(className) }

  return <div className={classNames.join(" ")}>
    <Score className="site-score-small" score={{name: 'Assets', score: ratings.assets, text: ratings.assetsLabel}} />
    <Score className="site-score-small" score={{name: 'Ease of Use', score: ratings.easeOfUse, text: ratings.easeOfUseLabel}} />
    <Score className="site-score-small" score={{name: 'Fees', score: ratings.fees, text: ratings.feesLabel}} />
    <Score className="site-score-small" score={{name: 'Safety', score: ratings.safety, text: ratings.safetyLabel}} />
    <Score className="site-score-small" score={{name: 'Staking', score: ratings.staking, text: ratings.stakingLabel}} />
    <Score className="site-score-small" score={{name: 'Trading Features', score: ratings.tradingFeatures, text: ratings.tradingFeaturesLabel}} />
  </div>
}

function Score(props: { className: string, score: SiteScore }) {
  const { score, className } = props;
  return <div className={`site-score ${className ? className : ''}`}>
    {score.name && <div className="score-name">{score.name}</div>}
    <div className="score-progress">
      <div className="score-progress-inner" style={{width: `${(score.score / 10) * 100}%`}}>
      </div>
    </div>
    <div className="score-value">{score.score.toFixed(1)}</div>
  </div>
}


export default Index

export const query = graphql`
  query ($language: String!) {
    sites: allWpSite(filter: { locale: { locale: { eq: $language } } }) {
      nodes {
        ...SiteInformationSmall
      }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
