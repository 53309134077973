module.exports = {
  'Algeria': 'dz',
  'Argentina': 'ar',
  'Australia': 'au',
  'Austria': 'at',
  'Belarus': 'by',
  'Belgium': 'be',
  'Brazil': 'br',
  'Bulgaria': 'bg',
  'Canada': 'ca',
  'Chile': 'cl',
  'China': 'cn',
  'Colombia': 'co',
  'Congo': 'cg',
  'Croatia': 'hr',
  'Czech Republic': 'cz',
  'Denmark': 'dk',
  'Dominicana': 'do',
  'Ecuador': 'ec',
  'Egypt': 'eg',
  'Estonia': 'ee',
  'Finland': 'fi',
  'France': 'fr',
  'Georgia': 'ge',
  'Germany': 'de',
  'Greece': 'gr',
  'Hong Kong': 'hk',
  'Hungary': 'hu',
  'India': 'in',
  'Indonesia': 'id',
  'Iran': 'ir',
  'Ireland': 'ie',
  'Israel': 'il',
  'Italy': 'it',
  'Japan': 'jp',
  'Kenya': 'ke',
  'Latvia': 'lv',
  'Lithuania': 'lt',
  'Luxembourg': 'lu',
  'Malaysia': 'my',
  'Malta': 'mt',
  'Mexico': 'mx',
  'Morocco': 'ma',
  'Netherlands': 'nl',
  'New Zealand': 'nz',
  'Nigeria': 'ng',
  'Norway': 'no',
  'Pakistan': 'pk',
  'Peru': 'pe',
  'Philippines': 'ph',
  'Poland': 'pl',
  'Portugal': 'pt',
  'Romania': 'ro',
  'Russia': 'ru',
  'Saudi Arabia': 'sa',
  'Serbia': 'rs',
  'Singapore': 'sg',
  'Slovakia': 'sk',
  'Slovenia': 'si',
  'South Africa': 'za',
  'South Korea': 'kr',
  'Spain': 'es',
  'Sweden': 'se',
  'Switzerland': 'ch',
  'Taiwan': 'tw',
  'Thailand': 'th',
  'Turkey': 'tr',
  'Ukraine': 'ua',
  'The UAE': 'ae',
  'United Kingdom': 'gb',
  'The USA': 'us',
  'Venezuela': 've',
  'Vietnam': 'vn',
}